<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div v-if="check_has_permission('sendPushNotification')" class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="sendNewNotification"
          icon-pack="feather"
          icon="icon-send"
        >Send Push Notification</vs-button>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="notificationDate"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th sort-key="userType">Sent To</vs-th>
          <vs-th sort-key="sentBy">Sent By</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].title">{{ data[indextr].title | capitalize }}</vs-td>
            <vs-td :data="data[indextr].message">{{ data[indextr].message | capitalize }}</vs-td>
            <vs-td :data="data[indextr].userType">{{ data[indextr].userType | capitalize}}</vs-td>
            <vs-td :data="data[indextr].sentBy">{{data[indextr].sentBy}}</vs-td>
            <vs-td
              :data="data[indextr].createdAt"
              style="white-space: nowrap;"
            >{{ data[indextr].createdAt | date_formatter }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="Delete Notification">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="popUpDelete = true"
                  icon-pack="feather"
                  icon="icon-trash"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
            <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
              <p align="center">Are you sure you want to delete this notification ?</p>
              <br />
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5" align="right">
                    <vs-button
                      size="lg"
                      color="primary"
                      @click="deleteDetailHandler(data[indextr]._id)"
                    >Yes, delete</vs-button>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5">
                    <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                  </div>
                </vs-col>
              </vs-row>
            </vs-popup>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    sendNotificationRoute: {
      type: String,
      default: "SuperAdminSendPushNotification"
    },
    sendSubAdminNotificationRoute: {
      type: String,
      default: "AdminSendPushNotification"
    }
  },
  data() {
    return {
      popUpDelete: false,
      noDataText: "",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10
      },
      serverResponded: false,
      notificationDate: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminSentNotifications",
      "deleteAdminSentNotifications"
    ]),
    async getNotificationList() {
      let self = this;
      await this.fetchAdminSentNotifications(self.dataTableParams)
        .then(res => {
          self.notificationDate = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total;
          self.page = res.data.data.pagination.page;
          self.serverResponded = true;
          self.noDataText = "No Notifications Sent Yet";
        })
        .catch(err => {
          console.log("This is the error ::", err);
        });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNotificationList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNotificationList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNotificationList();
    },
    async deleteDetailHandler(id) {
      await this.deleteAdminSentNotifications(id)
        .then(res => {
          this.getNotificationList();
          this.popUpDelete = false;
          this.$vs.notify({
            title: "Success",
            text: "Notification deleted successfully.",
            color: "success"
          });
        })
        .catch(err => {
          this.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        });
    },
    sendNewNotification() {
      if (this.$store.state.AppActiveUser.userType === "superAdmin")
        this.$router.push({ name: this.sendNotificationRoute });
      else this.$router.push({ name: this.sendSubAdminNotificationRoute });
    },
    forceRerender() {
      this.rerenderKey += 1;
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNotificationList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNotificationList();
      }
    },
    rerenderKey: function(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getNotificationList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  async created() {
    await this.getNotificationList();
  }
};
</script>
